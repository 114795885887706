$(document).ready(function () {
  window.scrollTo({ top: 0 });

  $(window).on('scroll',function(){
    onPageScroll(this);
  });
  onPageScroll(this);

  $(".menu-btn").click(function(){
    $('.menu-mobile').addClass('menu-mobile-open');
  });
  $(".close-btn").click(function(){
    $('.menu-mobile').removeClass('menu-mobile-open');
  });
});

function onPageScroll(_this) {
  var scroll = $(_this).scrollTop();
  var x = scroll * 2;
  var y = scroll * 2.5;                
  if (window.screen.width >= 800) {
      $("#video-overlay").css({"transform":"translateX(-" + x + "px)"});
      $("#video-overlay-inner").css({"transform":"translateX(-" + y + "px)"});
  }
  if(scroll > ($(window).width() >= 800 ? 200 : 400)) {
    $("#header").css({"transform":"translateY(0px)"});
  } else {
    $("#header").css({"transform":"translateY(-200px)"});
  }
}
AOS.init({
    duration: 800,
    disable: 'mobile',
});